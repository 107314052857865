<template>
  <div class="trends">
    <!-- 动态 -->
    <div class="secondMenu">
      <div class="WebMain">
        <ul>
          <li @click="changeSecond(item.id)" :class="item.id === active ? 'active' : ''" v-for="item in menuList"
            :key="item.id">
            <span class="span24">{{ item.title }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="news">
      <div class="WebMain">
        <div class="newsContent">
          <div class="item" v-for="item in newsList" :key="item.id" @click="toDetail(item.id)">
            <div class="img">
              <span class="time">{{ (item.created).slice(0, 10) }}</span>
              <img v-if="item.thumbnail" :src="$store.state.requestAPI + item.thumbnail" alt="">
              <img v-else src="../../assets/img/home/service (3).png" alt="">
            </div>
            <div class="summary">
              <div class="leftStyle"></div>
              <p class="title">{{ item.title }}</p>
            </div>
          </div>
        </div>
        <div class="block">
          <el-pagination @current-change="handleCurrentChange" :current-page.sync="paging.pageNum"
            :page-size="paging.pageSize" layout="prev, pager, next, jumper" :total="paging.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { menuListAPI } from '@/api/settings/EnglishMenu'
import { articleListAPI } from '@/api/article/EnglishArticle'
import { articleTypeListAPI } from '@/api/article/EnglishType'
export default {
  data() {
    return {
      menuList: [],
      active: null,
      newsList: [],
      paging: {
        pageSize: 8,
        pageNum: 1,
        total: 0
      },
      categoryId: 12
    }
  },
  methods: {
    // // 获取菜单列表
    // async getMenuList() {
    //   let info = { pageSize: 10000, pageNum: 1 }
    //   const res = await menuListAPI(JSON.stringify(info));
    //   console.log(res);
    //   if (res.code === 200) {
    //     res.data.list.forEach(item => {
    //       if (item.id === 10) {
    //         this.menuList = item.children;
    //         // console.log(this.menuList)
    //         this.active = this.menuList[0].id
    //       }

    //     });
    //   }
    // },
    // 获取新闻列表
    async getNewsList() {
      let info = { pageNum: this.paging.pageNum, pageSize: this.paging.pageSize, status: 'normal', categoryId: this.categoryId }
      const res = await articleListAPI(info);
      console.log(res);
      if (res.code === 200) {
        this.newsList = res.data.list;
        this.paging.total = res.data.total
      }
    },
    // 分页
    handleCurrentChange(val) {
      // console.log(val)
      this.paging.pageNum = val;
      this.getNewsList();
      // this.$store.commit('changePaging', {pageNum: val});
      // console.log(this.$store.state.paging)
      // 获取文章分类
      // setTimeout(() => {
      //   this.$store.commit('changePaging', { pageNum: val, total: this.$store.state.total });
      //   // console.log(this.$store.state.paging)
      // }, 100);
      // // this.$store.commit('changePaging', {pageNum: val});
      // this.$store.dispatch('getArticleType');


      // this.paging.pageNum = val;
      // this.getArticleList();
    },
    // 跳转至详情
    toDetail(val) {
      this.$router.push(`/trends/detail/${val}`)
    },
    // 获取文章分类列表
    async getList() {
      let info = { pageNum: 1, pageSize: 10000 };
      const res = await articleTypeListAPI(info);
      console.log(res);
      if (res.code === 200) {
        let list = res.data.list;
        let allId = 0;
        list.forEach(item => {
          if (item.id === 10) {
            // console.log(item);
            this.menuList = item.children;
            allId = item.id;
            this.categoryId = item.id
          }
        }
        )
        this.menuList = [{ id: allId, title: 'all' }, ...this.menuList]
        // console.log(this.menuList)
        this.active = this.menuList[0].id

      }
    },
    changeSecond(val) {
      // console.log(val)
      this.categoryId = val;
      this.active = val;
      this.getNewsList();
    }
  },
  created() {
    // this.getMenuList();
    this.getNewsList();
    this.getList();
  }
}
</script>

<style lang="scss" scoped>
.trends {

  .secondMenu {
    background-color: #fff;
    padding-top: 24px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);

    // height: 81px;
    // box-sizing: border-box;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin-right: 100px;
        padding-bottom: 18px;
        // line-height: 33px;

        cursor: pointer;

        &:last-child {
          margin: 0;
        }

        span {
          line-height: 33px;
          color: #222222;

        }
      }

      .active {
        span {
          color: var(--custom-color);
          font-weight: 600;
        }

        border-bottom: 6px solid var(--custom-color);
      }
    }

  }


  .news {
    background: url('../../assets/img/scheme/bg1.png');
    background-color: #F5F5F5;
    background-size: cover;
    padding-top: 40px;
    padding-bottom: 60px;

    .newsContent {
      min-height: 300px;
    }

    .block {
      text-align: right;
    }
  }
}


@media screen and (max-width:500px) {
  .trends .secondMenu ul li {
    margin-right: 35px;
  }
}
</style>